
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'

export default defineComponent({
  components: {
    TmButton,
    TmModal,
    TmFormLine,
  },
  props: {
    addNewQuestion: {
      type: Boolean,
    },
  },
  setup() {
    const username = ref('textmagic123')
    const questions = ref([
      'Your mother\'s maiden name',
      'Your first car',
      'Your mother\'s maiden name',
      'Your first car',
    ])
    const password = ref()
    const newQuestion = ref(questions.value[0])
    const newAnswer = ref()

    return {
      username,
      questions,
      newQuestion,
      newAnswer,
      password,
    }
  },
})
