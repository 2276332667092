import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_tm_form_line = _resolveComponent("tm-form-line")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_modal = _resolveComponent("tm-modal")!

  return (_openBlock(), _createBlock(_component_tm_modal, {
    id: "accountSecurityQuestion",
    "modal-title": _ctx.addNewQuestion ? 'New security question' : 'Change security question',
    size: "small"
  }, {
    "modal-content": _withCtx(() => [
      _createVNode(_component_tm_form_line, {
        label: "Username",
        class: "mb-3"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_field, {
            modelValue: _ctx.username,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event)),
            disable: ""
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      (!_ctx.addNewQuestion)
        ? (_openBlock(), _createBlock(_component_tm_form_line, {
            key: 0,
            label: "Password",
            class: "mb-3"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_tm_field, {
                modelValue: _ctx.password,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
                type: "password",
                placeholder: "Enter your password"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_tm_form_line, {
        label: "Choose new question",
        class: "mb-3"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_field, {
            modelValue: _ctx.newQuestion,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newQuestion) = $event)),
            type: "select",
            options: _ctx.questions,
            clearable: false
          }, null, 8, ["modelValue", "options"])
        ]),
        _: 1
      }),
      _createVNode(_component_tm_form_line, { label: "Enter your answer" }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_field, {
            modelValue: _ctx.newAnswer,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newAnswer) = $event)),
            placeholder: "Enter the answer to security question"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    "modal-footer-button": _withCtx(() => [
      _createVNode(_component_tm_button, {
        size: "large",
        color: "blue"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.addNewQuestion ? 'Add' : 'Save changes'), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modal-title"]))
}